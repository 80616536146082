import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Form,
  Menu,
  Checkbox,
  Button,
  Dropdown,
  Input,
  Tooltip,
  notification,
} from 'antd';
import { DownOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import './index.css';
import { useHttp } from '../../hooks/http.hook';
import getFiltersInColumnServer from '../../utils/filteredColumnsColor';
import { useAuth } from '../../hooks/auth.hook';
import ModalOffer from '../modalOffer/ModalOffer';
import SelectedColorFilterStore from '../../store/SelectedColorFilterStore';
import DirectoryStore from '../../store/DirectoryStore';
import { useLogUser } from '../../hooks/useLogUser.hook';

const UserOffersTable = () => {
  const { userId } = useAuth();
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { request, loading } = useHttp();
  const { postLog } = useLogUser();
  const [data, setData] = useState();
  const [visibleFilterMenu, setVisibleFilterMenu] = useState(false);
  const [checkedValuesFilter, setCheckedValuesFilter] = useState(
    localStorage.getItem('forwarderFields')?.split(',')
  );
  const [userfields, setUserfields] = useState(
    localStorage.getItem('forwarderFields')?.split(',')
  );
  const [filteredColumns, setFilteredColumns] = useState(null);
  const [dataForSelect, setDataForSelect] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allRowData, setAllRowData] = useState();
  const [storeColorFilter, setStoreColorFilter] = useState();
  const [limitPagination, setLimitPagination] = useState(
    localStorage.getItem('limitPagination')
      ? +localStorage.getItem('limitPagination')
      : 30
  );
  const [offsetPagination, setOffsetPagination] = useState(0);
  const [countPage, setCountPage] = useState(1);
  const [sortActiv, setSortActiv] = useState({
    responseCreateDate: 'noSort',
    sendingMethod: 'noSort',
    port: 'noSort',
    sendingStation: 'noSort',
    finalDestination: 'noSort',
    destination: 'noSort',
    currency: 'noSort',
    validityOfBidsStart: 'noSort',
    validityOfBidsEnd: 'noSort',
    pricePerFreight: 'noSort',
    finalPrice: 'noSort',
    price: 'noSort',
    transitTime: 'noSort',
  });

  const getCustomCellHeader = (title, nameColumn) => {
    return (
      <>
        <div
          className={`customSorterImg ${
            sortActiv[nameColumn] === 'DESC' && 'activSort'
          }`}
          onClick={() => {
            getSorterServer(nameColumn, 'DESC');
          }}
        >
          &#9650;
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            fetchDataOffers(true);
            setSortActiv({
              responseCreateDate: 'noSort',
              sendingMethod: 'noSort',
              port: 'noSort',
              sendingStation: 'noSort',
              finalDestination: 'noSort',
              destination: 'noSort',
              currency: 'noSort',
              validityOfBidsStart: 'noSort',
              validityOfBidsEnd: 'noSort',
              pricePerFreight: 'noSort',
              finalPrice: 'noSort',
              price: 'noSort',
              transitTime: 'noSort',
            });
          }}
        >
          {title}
        </div>
        <div
          className={`customSorterImg ${
            sortActiv[nameColumn] === 'ASC' && 'activSort'
          }`}
          onClick={() => {
            getSorterServer(nameColumn, 'ASC');
          }}
        >
          &#9660;
        </div>
      </>
    );
  };

  const openNotification = (id) => {
    const key = id;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          handlerDeleteOffer(id);
          notification.close(key);
        }}
      >
        Удалить
      </Button>
    );
    notification.info({
      message: 'Подтвердите удаление',
      description: `Вы действительно хотите удалить заявку?`,
      btn,
      key,
      placement: 'top',
    });
  };

  const columns = [
    {
      title: 'Действие',
      dataIndex: 'actionBtns',
      key: 'actionBtns',
      width: 100,
      render: (text, record) => {
        const isCanDelete =
          moment(Date.now()).format('DD MM YYYY') ===
          moment(record.responseCreateDate).format('DD MM YYYY');

        return (
          <div className="UserOffersTable__wrapperBtns">
            {isCanDelete && (
              <Button
                className="UserOffersTable__btn"
                onClick={() => {
                  openNotification(record.id);
                }}
                icon={
                  <DeleteOutlined
                    style={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color: 'red',
                    }}
                  />
                }
              />
            )}
            {
              <Button
                className="UserOffersTable__btn"
                onClick={() => {
                  /* 
                  при клике на кнопку копирования дата создания заявки сравнивается с 08.05.2024 16:00
                  скопировать можно только заявки, созданные позже (нового образца)
                  */
                  if (
                    moment(record?.responseCreateDate).isSameOrAfter(
                      moment('08-05-2024 16:00', 'DD-MM-YYYY HH:mm')
                    )
                  ) {
                    fetchRowData(record.id);
                    postLog('Заявки (reslog)', 'скопирована заявка');
                  } else {
                    notification.warning({
                      message: 'Формат заявки устарел',
                      description: `Необходимо создать новую`,
                      placement: 'top',
                    });
                  }
                }}
                icon={
                  <CopyOutlined
                    style={{
                      fontSize: '16px',
                      color: '#1890ff',
                    }}
                  />
                }
              />
            }
          </div>
        );
      },
    },
    {
      title: getCustomCellHeader('Дата создания', 'responseCreateDate'),
      dataIndex: 'responseCreateDate',
      key: 'responseCreateDate',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'responseCreateDate',
          null,
          true,
          null,
          null,
          handlerColorFilter,
          handlerColorDateFilter
        ),
      render: (text, record) => {
        return !text ? '' : moment(text).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: 'Экспедитор',
      dataIndex: 'forwarder.name',
      key: 'forwarder.name',
      width: 100,
      render: (text, record) => {
        return record.forwarder ? record.forwarder.name : '';
      },
    },
    {
      title: 'Условия поставки',
      dataIndex: 'termsOfDelivery',
      key: 'termsOfDelivery',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'termsOfDelivery',
          null,
          null,
          null,
          null,
          handlerColorFilter
        ),
      render: (text, record) => {
        return text;
      },
    },
    {
      title: 'Агент',
      dataIndex: 'agent_contact.contact',
      key: 'agent_contact.contact',
      width: 100,
      render: (text, record) => {
        return record.agent_contact
          ? `${record['agent_contact'].contact} (${record['agent_contact'].agent.name})`
          : '';
      },
    },
    {
      title: getCustomCellHeader('Метод отправки', 'sendingMethod'),
      dataIndex: 'sendingMethod',
      key: 'sendingMethod',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'sendingMethod',
          null,
          null,
          null,
          null,
          handlerColorFilter
        ),
      render: (text, record) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Порт отправки', 'port'),
      dataIndex: 'port',
      key: 'port',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'port',
          null,
          null,
          null,
          null,
          handlerColorFilter
        ),
      render: (text, record) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Станция отправки', 'sendingStation'),
      dataIndex: 'sendingStation',
      key: 'sendingStation',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'sendingStation',
          null,
          null,
          null,
          null,
          handlerColorFilter
        ),
      render: (text, record) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Терминал', 'finalDestination'),
      dataIndex: 'finalDestination',
      key: 'finalDestination',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'finalDestination',
          null,
          null,
          null,
          null,
          handlerColorFilter
        ),
      render: (text, record) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Конечный пункт', 'destination'),
      dataIndex: 'destination',
      key: 'destination',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'destination',
          'destination', // для этой колонки используется стор, для корректного отображения и работы фильтра
          null,
          null,
          null,
          handlerColorFilter
        ),
      render: (text, record) => {
        return text;
      },
    },
    {
      title: 'Линия',
      dataIndex: 'line',
      key: 'line',
      width: 100,
      render: (text, record) => {
        return text;
      },
    },
    {
      title: 'Условия по льготному использованию контейнерного оборудования',
      dataIndex: 'condition',
      key: 'condition',
      width: 100,
      render: (text, record) => {
        return text;
      },
    },
    {
      title: 'Ставки за сверхнормативное использование оборудования ($/сутки)',
      dataIndex: 'excessEquipmentUsagePriceUsd',
      key: 'excessEquipmentUsagePriceUsd',
      width: 160,
      render: (text, record) => {
        // если в записи есть массив ставок нового образца, то рендерится он в виде списка
        if (record?.excessEquipmentUsagePeriodsPrices?.length) {
          return record?.excessEquipmentUsagePeriodsPrices?.map((item) => (
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>
                с {item.startDay} дня по {item.endDay} день: {item.price}$
              </li>
            </ul>
          ));
        }
        // если массива ставок нет, то рендерится старый вариант или "нет данных"
        return record?.excessEquipmentUsagePriceUsd || 'нет данных';
      },
    },
    {
      title: 'Тип груза',
      dataIndex: 'general',
      key: 'general',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'general',
          null,
          null,
          null,
          null,
          handlerColorFilter
        ),
      width: 100,
      render: (text, record) => {
        return (
          <div className="UserOffersTable__cellWrapper">
            <div className="UserOffersTable__cellItem">
              {record.general && 'ген.груз'}
            </div>
            <div className="UserOffersTable__cellItem">
              {record.oil && 'масло'}
            </div>
            <div className="UserOffersTable__cellItem">
              {record.batteries && 'акб'}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Дата выхода из Китая',
      dataIndex: 'exitDateFromChina',
      key: 'exitDateFromChina',
      width: 100,
      render: (text, record) => {
        return !text ? '' : moment(text).format('DD-MM-YYYY');
      },
    },
    {
      title: getCustomCellHeader('Валидность ставок(с)', 'validityOfBidsStart'),
      dataIndex: 'validityOfBidsStart',
      key: 'validityOfBidsStart',
      width: 100,
      render: (text, record) => {
        return !text ? '' : `${moment(text).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: getCustomCellHeader('Валидность ставок(по)', 'validityOfBidsEnd'),
      dataIndex: 'validityOfBidsEnd',
      key: 'validityOfBidsEnd',
      width: 100,
      render: (text, record) => {
        return !text ? '' : `${moment(text).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: 'Тип КТК',
      dataIndex: 'containerType',
      key: 'containerType',
      width: 100,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumnServer(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'containerType',
          null,
          null,
          null,
          null,
          handlerColorFilter
        ),
      render: (text, record) => {
        return text;
      },
    },

    {
      title: 'Комментарий к цене',
      dataIndex: 'pricePerFreightComment',
      key: 'pricePerFreightComment',
      width: 100,
      render: (text, record) => {
        return (
          <Tooltip
            placement="topLeft"
            title={text && text.length > 26 ? text : ''}
          >
            <div className="shortcutСomment">{text}</div>
          </Tooltip>
        );
      },
    },
    {
      title: getCustomCellHeader(
        'Цена от терминала до конечного пункта (РУБ.)',
        'price'
      ),
      dataIndex: 'price',
      key: 'price',
      width: 100,
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Комментарий к цене',
      dataIndex: 'priceComment',
      key: 'priceComment',
      width: 100,
      render: (text, record) => {
        return (
          <Tooltip
            placement="topLeft"
            title={text && text.length > 26 ? text : ''}
          >
            <div className="shortcutСomment">{text}</div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Возможные трудности при перевозке',
      dataIndex: 'riskLevel',
      key: 'riskLevel',
      width: 100,
      render: (text, record) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Транзитное время', 'transitTime'),
      dataIndex: 'transitTime',
      key: 'transitTime',
      width: 100,
      render: (text, record) => {
        const date = text?.split(',');

        return !text
          ? ''
          : `${moment(date[0]).format('DD-MM-YYYY')} /
              ${moment(date[1]).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      width: 100,
      render: (text, record) => {
        return (
          <Tooltip
            placement="topLeft"
            title={text && text.length > 26 ? text : ''}
          >
            <div className="shortcutСomment">{text}</div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text, record) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Валюта', 'currency'),
      dataIndex: 'currency',
      key: 'currency',
      width: 100,
      render: (text, record) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Цена за фрахт ($)', 'pricePerFreight'),
      dataIndex: 'pricePerFreight',
      key: 'pricePerFreight',
      width: 100,
      render: (text) => {
        return text;
      },
    },
    {
      title: getCustomCellHeader('Итого цена', 'finalPrice'),
      dataIndex: 'finalPrice',
      key: 'finalPrice',
      className: 'UserOffersTable__column_finalPrice',
      render: (text) => {
        return text;
      },
    },
  ];

  const onFinish = async (values) => {};

  const fetchRowData = async (id) => {
    try {
      const resRowData = await request(`api/offer/response/${id}`);
      await setAllRowData(resRowData);
      setIsModalVisible(true);
    } catch (error) {
      console.log('error-fetchRowData >>>', error);
    }
  };

  const fetchDataSelect = async () => {
    try {
      const resDataSelect = await request('api/offer/data-for-select');
      // данные для фильтра "Конечный пункт" добавляются в стор для корректной работы фильтра
      DirectoryStore.setDirectory(resDataSelect.destination, 'destination');

      setDataForSelect({
        termsOfDelivery: resDataSelect.termsOfDelivery,
        sendingMethod: resDataSelect.sendingMethod.filter(
          (item) =>
            item.name !== 'Калининград (by sea/railway/ferry)' &&
            item.name !== 'СПб'
        ),
        terminal: resDataSelect.finalDestination,
        containerType: resDataSelect.containerType,
        agentContacts: resDataSelect.agentContacts,
        condition: resDataSelect.condition,
        port: resDataSelect.port,
        destination: resDataSelect.destination,
        sendingStation: resDataSelect.sendingStation
          ? resDataSelect.sendingStation
          : [],
        waypoint: resDataSelect.waypoint,
      });
    } catch (error) {
      console.log('error-getDataSelect >>>', error);
    }
  };

  const fetchDataOffers = async (resetSort) => {
    let columnName = null;
    let sortName = null;
    for (let item in sortActiv) {
      if (sortActiv[item] !== 'noSort') {
        columnName = item;
        sortName = sortActiv[item];
      }
    }

    try {
      // await request(process.env.REACT_APP_CHECK_TOKEN);
      const resData = await request(
        !resetSort && columnName && sortName
          ? `api/offer/response?limit=${limitPagination}&offset=${offsetPagination}&order[${columnName}]=${sortName}`
          : `api/offer/response?limit=${limitPagination}&offset=${offsetPagination}&order[responseCreateDate]=DESC`,
        'POST',
        SelectedColorFilterStore.getValues()
      );
      const newResData = resData.rows.map((item) => {
        return {
          ...item,
          key: item?.id,
        };
      });
      setData(newResData);
    } catch (error) {
      console.log('error-UserOffersTable >>>', error);
    }
  };

  const getSorterServer = async (nameColumn, sort) => {
    try {
      // await request(process.env.REACT_APP_CHECK_TOKEN);
      const resData = await request(
        `api/offer/response?limit=${limitPagination}&offset=${offsetPagination}&order[${nameColumn}]=${sort}`,
        'POST',
        SelectedColorFilterStore.getValues()
      );
      const newResData = resData.rows.map((item) => {
        return {
          ...item,
          key: item?.id,
        };
      });
      setSortActiv({
        responseCreateDate: 'noSort',
        sendingMethod: 'noSort',
        port: 'noSort',
        sendingStation: 'noSort',
        finalDestination: 'noSort',
        destination: 'noSort',
        currency: 'noSort',
        validityOfBidsStart: 'noSort',
        validityOfBidsEnd: 'noSort',
        pricePerFreight: 'noSort',
        finalPrice: 'noSort',
        price: 'noSort',
        transitTime: 'noSort',
        [nameColumn]: sort,
      });
      setData(newResData);
    } catch (error) {
      console.log('error-UserOffersTable >>>', error);
    }
  };

  const handlerDeleteOffer = async (id) => {
    try {
      await request(`api/offer/response/${id}`, 'DELETE');
      fetchDataOffers();
      postLog('Заявки (reslog)', 'удалена заявка');
    } catch (error) {
      console.log('ERROR - handlerDeleteOffer >>>', error);
    }
  };

  const handlerColorFilter = async (values) => {
    const keyNames = Object.keys(values);

    if (keyNames.length > 1) {
      // дополнительный if для "Тип груза" (нестандартный объект)
      SelectedColorFilterStore.setValues({ ...storeColorFilter, ...values });
    } else {
      const nameKey = Object.keys(values)[0];
      if (values[nameKey].length > 0) {
        SelectedColorFilterStore.setValues({ ...storeColorFilter, ...values });
      } else {
        if (storeColorFilter) {
          delete storeColorFilter[nameKey];
          SelectedColorFilterStore.setValues(storeColorFilter);
        }
      }
    }

    try {
      // await request(process.env.REACT_APP_CHECK_TOKEN);
      await fetchDataOffers();
    } catch (error) {
      console.log('error-handlerColorFilter >>>', error);
    }
  };

  const handlerColorDateFilter = async (values) => {
    // для корректной фильтрации на проде добавили datePlusOneDay
    const datePlusOneDay = moment(values.responseCreateDateTo)
      .add('days', 1)
      .format('YYYY-MM-DD');

    const newValues = {
      responseCreateDateFrom: values.responseCreateDateFrom,
      responseCreateDateTo: values.responseCreateDateTo ? datePlusOneDay : null,
    };

    SelectedColorFilterStore.setValues({ ...storeColorFilter, ...newValues });
    try {
      // await request(process.env.REACT_APP_CHECK_TOKEN);
      await fetchDataOffers();
    } catch (error) {
      console.log('error-handlerColorDateFilter >>>', error);
    }
  };

  const showModal = (value) => {
    setIsModalVisible(value);
  };

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setCheckedValuesFilter([
        'responseCreateDate',
        'id',
        'forwarderId',
        'agentContactId',
        'forwarder.name',
        'agent_contact.contact',
        'termsOfDelivery',
        'sendingMethod',
        'port',
        'sendingStation',
        'finalDestination',
        'destination',
        'line',
        'condition',
        'excessEquipmentUsagePriceUsd',
        'general',
        'oil',
        'batteries',
        'exitDateFromChina',
        'validityOfBidsEnd',
        'validityOfBidsStart',
        'containerType',
        'pricePerFreight',
        'pricePerFreightComment',
        'price',
        'priceComment',
        'riskLevel',
        'transitTime',
        'comment',
        'status',
        'currency',
        'finalPrice',
      ]);
    } else {
      setCheckedValuesFilter([
        'finalPrice',
        'id',
        'responseCreateDate',
        'oil',
        'batteries',
      ]);
    }
  };

  const optionsCheckboxFiltres = [
    {
      label: 'Дата создания',
      value: 'responseCreateDate',
      disabled: true,
      defaultChecked: true,
    },
    { label: 'Экспедитор', value: 'forwarder.name' },
    { label: 'Условия поставки', value: 'termsOfDelivery' },
    { label: 'Метод отправки', value: 'sendingMethod' },
    { label: 'Порт отправки', value: 'port' },
    { label: 'Станция отправки', value: 'sendingStation' },
    { label: 'Терминал', value: 'finalDestination' },
    { label: 'Конечный пункт', value: 'destination' },
    { label: 'Линия', value: 'line' },
    { label: 'Условия по льготному использованию', value: 'condition' },
    {
      label: 'Ставки за сверхнормативное использование оборудования ($/сутки)',
      value: 'excessEquipmentUsagePriceUsd',
    },
    { label: 'тип груза', value: 'general' },
    { label: 'Дата выхода из Китая', value: 'exitDateFromChina' },
    { label: 'Валидность ставок(с)', value: 'validityOfBidsStart' },
    { label: 'Валидность ставок(по)', value: 'validityOfBidsEnd' },
    { label: 'Тип КТК', value: 'containerType' },
    { label: 'Цена за фрахт', value: 'pricePerFreight' },
    { label: 'Комментарий к цене за фрахт', value: 'pricePerFreightComment' },
    { label: 'Цена от терминала до конечного пункта', value: 'price' },
    { label: 'Комментарий к цене', value: 'priceComment' },
    { label: 'Возможные трудности при перевозке', value: 'riskLevel' },
    { label: 'Транзитное время', value: 'transitTime' },
    { label: 'Комментарий', value: 'comment' },
    { label: 'Статус', value: 'status' },
    { label: 'Валюта', value: 'currency' },
    {
      label: 'итого цена',
      value: 'finalPrice',
      disabled: true,
      defaultChecked: true,
    },
  ];

  const onChangeCheckboxFilter = (checked) => {
    setCheckedValuesFilter([
      'id',
      'agent_contact.contact',
      'forwarderId',
      'agentContactId',
      'oil',
      'batteries',
      ...checked,
    ]);
  };

  const updateUserFields = async () => {
    // await request(process.env.REACT_APP_CHECK_TOKEN);

    const resData = await request(`api/user/${userId}`, 'PATCH', {
      forwarderFields: checkedValuesFilter.join(','),
    });
    localStorage.setItem('forwarderFields', resData.forwarderFields);
    setUserfields(resData.forwarderFields);
    setVisibleFilterMenu(false);
    fetchDataOffers();
  };

  useEffect(() => {
    if (userfields) {
      const newColumns = columns.filter((el) => {
        return userfields.indexOf(el.dataIndex) > -1;
      });
      setFilteredColumns([columns[0], ...newColumns]);
    }
  }, [userfields]);

  const menuFilters = (
    <Menu>
      <Checkbox className="CheckAllChange" onChange={onCheckAllChange}>
        Check all
      </Checkbox>
      <Checkbox.Group
        className="CheckboxGroup"
        options={optionsCheckboxFiltres}
        value={checkedValuesFilter}
        defaultValue={userfields}
        onChange={onChangeCheckboxFilter}
      />

      <Button
        className="btnCheckbox"
        type="primary"
        onClick={updateUserFields}
        disabled={loading}
      >
        сохранить
      </Button>
    </Menu>
  );

  useEffect(async () => {
    await fetchDataSelect();
    await fetchDataOffers();
    postLog('Заявки (reslog)', 'переход в Мои предложения');
  }, [offsetPagination, limitPagination]);

  useEffect(() => {
    SelectedColorFilterStore.setValues({});
  }, []);

  useEffect(() => {
    setUserfields(localStorage.getItem('forwarderFields')?.split(',')); // -- для повторной отрисовки колонок
  }, [data]);

  useEffect(() => {
    setStoreColorFilter(SelectedColorFilterStore.getValues());
  }, [handlerColorFilter]);

  return (
    <>
      <div className="UserOffersTable__header">
        <div className="dropdownWrapper">
          <Dropdown
            dropdownRender={() => menuFilters}
            trigger={'click'}
            open={visibleFilterMenu}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => setVisibleFilterMenu(!visibleFilterMenu)}
            >
              Фильтры <DownOutlined />
            </a>
          </Dropdown>
        </div>
        <div className="pagination__limitWrapper">
          <span className="pagination__limitText">Показывать по </span>
          <Input
            className="pagination__limitInput"
            style={{ width: '40px', textAlign: 'center' }}
            defaultValue={limitPagination}
            ref={inputRef}
            onPressEnter={(e) => {
              setLimitPagination(+e.target.value);
              localStorage.setItem('limitPagination', e.target.value);
              inputRef.current.blur();
            }}
          ></Input>
          <span className="pagination__limitText"> строк на странице</span>
        </div>
      </div>
      <Form form={form} onFinish={onFinish}>
        <Table
          columns={filteredColumns}
          dataSource={data}
          pagination={false}
          loading={loading}
          rowClassName={(record) => (record.color ? record.color : '')}
          scroll={{
            x: 'max-content',
          }}
        />
      </Form>

      <div className="paginationWrapper">
        <Button
          className="arrowPagination"
          onClick={() => {
            setOffsetPagination(offsetPagination - limitPagination);
            setCountPage(countPage - 1);
          }}
          disabled={offsetPagination === 0}
        >
          <span className="arrowPagination__size">&#8249; </span>
        </Button>
        <span className="pagination__countPage">{countPage}</span>

        <Button
          className="arrowPagination"
          onClick={() => {
            setOffsetPagination(offsetPagination + limitPagination);
            setCountPage(countPage + 1);
          }}
          disabled={data?.length < limitPagination}
        >
          <span className="arrowPagination__size">&#8250; </span>
        </Button>
      </div>

      {isModalVisible && (
        <ModalOffer
          dataForSelect={dataForSelect}
          showModal={showModal}
          fetchDataOffers={fetchDataOffers}
          copyData={allRowData}
          fetchDataSelect={fetchDataSelect}
        />
      )}
    </>
  );
};

export default UserOffersTable;
